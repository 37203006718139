import NewFooter from '../NewFooter/NewFooter';
import ProjectLogo from '../ProjectLogo/ProjectLogo';
import { ESgFooterProps } from './types';

const ESgFooter = ({ skewOverlap }: ESgFooterProps) => {
  return (
    <NewFooter
      copyrightText="enercity Speichervermarktungsgesellschaft mbH"
      foundationSlot={
        <>
          <NewFooter.Link href="/impressum">Impressum</NewFooter.Link>
          <NewFooter.Link href="/datenschutz">Datenschutz</NewFooter.Link>
          <NewFooter.Link href="/seitenuebersicht">
            Seitenübersicht
          </NewFooter.Link>
        </>
      }
      logoSlot={<ProjectLogo height={54} project="esg" solidFill />}
      skewOverlap={skewOverlap}
    />
  );
};

export default ESgFooter;
