import ComponentList from '@uikit/components/ComponentList/ComponentList';
import Container from '@uikit/components/Container/Container';
import ESgFooter from '@uikit/components/ESgFooter/ESgFooter';
import ESgHeader from '@uikit/components/ESgHeader/ESgHeader';
import ESgMovementDataWrapper from '@/components/ESgMovementDataWrapper/ESgMovementDataWrapper';
import Headline from '@uikit/components/Headline/Headline';
import IframeSection from '@uikit/components/IframeSection/IframeSection';
import ImageGallery from '@uikit/components/ImageGallery/ImageGallery';
import Modal from '@uikit/components/Modal/Modal';
import Section from '@uikit/components/Section/Section';
import SectionVisual from '@uikit/components/SectionVisual/SectionVisual';
import StageBoxText from '@uikit/components/StageBoxText/StageBoxText';
import TeaserWithImage from '@uikit/components/TeaserWithImage/TeaserWithImage';
import TwoColumnContainer from '@uikit/components/TwoColumnContainer/TwoColumnContainer';
import HeroArea from '@uikit/composites/HeroArea/HeroArea';
import HeroAreaContent from '@uikit/composites/HeroArea/HeroAreaContent';
import SmallHeroArea from '@uikit/composites/SmallHeroArea/SmallHeroArea';
import React from 'react';
import Forms from '../components/Forms/Forms';

export const CmsComponentsESg = {
  'esg-footer': ESgFooter,
  'esg-header': ESgHeader,
  'esg-stage-box-text': StageBoxText,
  'esg-section': Section,
  'esg-container': Container,
  'esg-stage-small': SmallHeroArea,
  'esg-hero-area-content': HeroAreaContent,
  'esg-stage': HeroArea,
  'eng-headline': Headline,
  'esg-stage-area': React.Fragment,
  'esg-section-visual': SectionVisual,
  'esg-two-column-container': TwoColumnContainer,
  'esg-modal': Modal,
  'esg-iframe-section': IframeSection,
  'esg-image-gallery': ImageGallery,
  'esg-download-area': React.Fragment,
  'esg-contact-area': React.Fragment,
  'esg-component-list': ComponentList,
  'esg-forms': Forms,
  'esg-teaser-with-image': TeaserWithImage,
  'esg-movement-data': ESgMovementDataWrapper,
};
