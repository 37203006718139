import React from 'react';
import useEsgMovementData from '@/hooks/useEsgMovementData/useEsgMovementData';
import ESgMovementData from '@uikit/composites/ESgMovementData/ESgMovementData';
import ErrorMessage from '@uikit/components/ErrorMessage/ErrorMessage';
import LoadingModule from '@uikit/components/LoadingModule/LoadingModule';
import { ESG_MOVEMENT_DATA_PATHS } from './consts';

const ESgMovementDataWrapper = () => {
  const { data, isError, isLoading } = useEsgMovementData(
    ESG_MOVEMENT_DATA_PATHS.currentMonth,
    ESG_MOVEMENT_DATA_PATHS.lastMonth
  );

  if (isError) {
    return (
      <ErrorMessage>Die Komponente konnte nicht angezeigt werden</ErrorMessage>
    );
  }

  if (isLoading) {
    return <LoadingModule handleEnterFrame={0} />;
  }

  return <ESgMovementData {...data} />;
};

export default ESgMovementDataWrapper;
