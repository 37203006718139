import { TableData } from './interfaces';

export default function extractEsgMovementData(
  csv: string
): TableData | undefined {
  const head = [
    'Datum',
    'Einlagerung (kWh)',
    'Auslagerung (kWh)',
    'Arbeitsgasvolumen Füllstand (kWh)',
  ];

  if (!csv) {
    return;
  }

  const rows = csv.split('\r\n').filter((row) => row !== '');
  const relevantRows = rows.slice(3); // Remove irrelevant header rows

  const body = relevantRows.map((value) => {
    const row = value.split(';');
    row.splice(1, 4); // Remove irrelevant cells

    return head.map((_, index) => {
      const value = row[index];

      if (!value) {
        return '';
      }

      return isNaN(Number(value))
        ? value
        : Number(value).toLocaleString('de-DE');
    });
  });

  return { head, body };
}
