import * as ajax from '@/helpers/ajax';
import { logger } from '@/helpers/logger';
import { useQuery } from 'react-query';
import { TableData } from './interfaces';
import extractEsgMovementData from './extractEsgMovementData';

type EsgMovementDataResult = {
  currentMonthData: TableData | undefined;
  lastMonthData: TableData | undefined;
};

const getEsgMovementData =
  (currentMonthCsvPath: string, lastMonthCsvPath: string) =>
  async (): Promise<EsgMovementDataResult | undefined> => {
    try {
      const [currentMonthData, lastMonthData] = await Promise.all([
        ajax.getText(currentMonthCsvPath),
        ajax.getText(lastMonthCsvPath),
      ]);

      return {
        currentMonthData: extractEsgMovementData(currentMonthData),
        lastMonthData: extractEsgMovementData(lastMonthData),
      };
    } catch (error) {
      logger.log('getEsgMovementData', error);
    }
  };

export default function useEsgMovementData(
  currentMonthCsvPath: string,
  lastMonthCsvPath: string
) {
  return useQuery(
    'esg-movement-data',
    getEsgMovementData(currentMonthCsvPath, lastMonthCsvPath)
  );
}
