import ContentsToReact from '@/components/ContentsToReact/ContentsToReact';
import GenericModals from '@/components/GenericModals/GenericModals';
import Page from '@/components/Page/Page';
import { extractContents } from '@/helpers/extractContents';
import extractPageMetaFromRouteData from '@/helpers/extractPageMetaFromRouteData';
import useBodyClass from '@/hooks/useBodyClass/useBodyClass';
import { useInitialPageDataLayer } from '@/hooks/useInitialPageDataLayer/useInitialPageDataLayer';
import type { PageModel } from '@/models/page';
import BackToTopButton from '@uikit/components/BackToTopButton/BackToTopButton';
import Layout from '@uikit/components/Layout/Layout';
import { LayoutVariant } from '@uikit/components/Layout/interfaces';
import { CmsComponentsContext } from '../CmsComponentsContext';
import cmsComponents from './ESgDefaultPage.cmsComponents';
import type { DefaultPageProps } from './interfaces';

const ESgDefaultPage = (pageData: PageModel<DefaultPageProps>): JSX.Element => {
  const currentPageData = pageData;

  const meta = extractPageMetaFromRouteData(currentPageData);

  const { contents } = currentPageData;

  const {
    extractedContents: [footerContents, noticeBarArea],
    restContents,
  } = extractContents(['esg-footer', 'notice-bar-area'], contents);

  useBodyClass(['esg']);

  useInitialPageDataLayer();

  return (
    <CmsComponentsContext.Provider value={cmsComponents}>
      <Layout variant={LayoutVariant.Default}>
        {noticeBarArea && <ContentsToReact contents={noticeBarArea} />}

        <Page meta={meta}>
          {restContents && <ContentsToReact contents={restContents} />}
        </Page>

        <BackToTopButton />

        <ContentsToReact contents={footerContents} />
      </Layout>

      <GenericModals modals={pageData.modals} />
    </CmsComponentsContext.Provider>
  );
};

// eslint-disable-next-line react/display-name
export default ESgDefaultPage;
