import React from 'react';
import type { LoadingModuleProps } from './interfaces';
import styles from './LoadingModule.module.scss';
import Container from '../Container/Container';
import Lottie from '../Lottie/Lottie';
import defaultAnimation from './animation.json';

const LoadingModule = ({
  animationData = defaultAnimation,
  handleEnterFrame,
}: LoadingModuleProps) => {
  return (
    <div>
      <Container flexCenter>
        <Lottie
          className={styles.annimation}
          animationData={animationData}
          autoplay
          loop
          onEnterFrame={handleEnterFrame}
          name="loading-animation"
        />
      </Container>
    </div>
  );
};

export default LoadingModule;
